import { render } from "./DashboardSpaceSummary.vue?vue&type=template&id=04ec10b9"
import script from "./DashboardSpaceSummary.vue?vue&type=script&lang=js"
export * from "./DashboardSpaceSummary.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "04ec10b9"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('04ec10b9', script)) {
    api.reload('04ec10b9', script)
  }
  
  module.hot.accept("./DashboardSpaceSummary.vue?vue&type=template&id=04ec10b9", () => {
    api.rerender('04ec10b9', render)
  })

}

script.__file = "src/components/dashboard/DashboardSpaceSummary.vue"

export default script