import { render } from "./DashboardRiskBadge.vue?vue&type=template&id=7ba733eb"
import script from "./DashboardRiskBadge.vue?vue&type=script&lang=js"
export * from "./DashboardRiskBadge.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7ba733eb"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7ba733eb', script)) {
    api.reload('7ba733eb', script)
  }
  
  module.hot.accept("./DashboardRiskBadge.vue?vue&type=template&id=7ba733eb", () => {
    api.rerender('7ba733eb', render)
  })

}

script.__file = "src/components/dashboard/DashboardRiskBadge.vue"

export default script